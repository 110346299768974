import React from 'react';
import Holdingpagecontent from '../components/holdingPageContent';
import Header from '../components/header';

const page = () =>{
    return (
        
        <main>
            <Header/>
            <Holdingpagecontent/>
        </main>
        
    );
}

export default page;