import React from 'react';
import LogoIcon from '../assets/firefly_logo_dark-grey.png';
import Envelope from '../assets/envelope.png';

function Header () {
    return (
        <div class="container">
            <div class="header">
                <div class="left quarter"><img class="FFLogoImg" src={LogoIcon} alt="firefly logo"/></div>
                
                <div class="right quarter"><a href="mailto:beth@firefly.co.uk"><img class="Envelope" src={Envelope} alt="mail us"/></a></div>
            </div>
        </div>
    );
}

export default Header;