import React from 'react';
import Logo from '../assets/logo.png';
import LogoTwo from '../assets/logoTwo.png'

const Holdingpagecontent = () => {
    return (
        <div class="holdingPageSection">
            <div class="fireflyTextLogo">
                <img class="image1" src={Logo} alt="firefly text logo"/>
                <img class="image2" src={LogoTwo} alt="firefly text logo"/>
            </div>
            <h3>Developing the Leaders of the Future</h3>
            <h3>Developing the Leader in You</h3>
        </div>
    
    );
}

export default Holdingpagecontent;